const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['vinhuset-cart-icon'] = {
        c: () => require('partner/modules/vinhuset-cart-icon/vinhuset-cart-icon.tsx'),
        $type: 'containerModule',
        da: [{name:'cart',  path:'@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action', runOn: 0},{name:'catalogs',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-catalogs', runOn: 0},{name:'channelDeliveryOptionConfig',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-channel-delivery-option-configuration', runOn: 0},{name:'customerBalances',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer-balance', runOn: 0},{name:'customerInformation',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer', runOn: 0},{name:'featureState',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-feature-state', runOn: 0},{name:'orgUnitLocations',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/search-org-unit-locations', runOn: 0}],
        
        iNM: false,
        ns: '__local__',
        n: 'vinhuset-cart-icon',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/vinhuset-cart-icon'
    });
        

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-catalogs';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-catalogs');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-channel-delivery-option-configuration';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-channel-delivery-option-configuration');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer-balance';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer-balance');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-feature-state';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-feature-state');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/search-org-unit-locations';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/search-org-unit-locations');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action';
            let dataAction = require('@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|modules|vinhuset-cart-icon|vinhuset-cart-icon'] = {
                c: () => require('partner/modules/vinhuset-cart-icon/vinhuset-cart-icon.view.tsx'),
                cn: '__local__-__local__-vinhuset-cart-icon'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };